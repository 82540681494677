import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class PageService {
  getAllByDevice = async (deviceId,skip) => {
    const url = `${URL_APP_BASE_PATH}/pages?deviceId=${deviceId}&skip=${skip}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  get = async (pageId) => {
    const url = `${URL_APP_BASE_PATH}/pages/${pageId}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  update = async (page) => {
    const url = `${URL_APP_BASE_PATH}/pages`;
    try {
      const { data } = await api.put(url,page);
      return data;
    } catch (err) {
      return null;
    }
  };

  delete = async (pageId) => {
    const url = `${URL_APP_BASE_PATH}/pages/${pageId}`;
    try {
      const { data } = await api.delete(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  reorder = async (pages) => {
    const url = `${URL_APP_BASE_PATH}/pages/reorder`;
    try {
      const { data } = await api.put(url,pages);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const pageService = new PageService();
export default pageService;
