import { tokenlessApi } from "./api";
import {
  URL_AUTH_BASE_PATH,
} from "../configs/constants";

export class AuthService {
  login = async (login, password) => {
    const requestBody = {
      email: login,
      password,
    };

    try {
      const { data } = await tokenlessApi.post(
        `${URL_AUTH_BASE_PATH}/users/login`, 
        requestBody
      );
      return data;    
    } catch (err) {
      return null;
    }
  };

  passwordReset = async (email) => {
    const requestBody = {
      email: email
    };

    try {
      const { data } = await tokenlessApi.post(
        `${URL_AUTH_BASE_PATH}/users/password/reset`, 
        requestBody
      );
      return data;    
    } catch (err) {
      return null;
    }
  };

  changePassword = async (token, password) => {
    const requestBody = {
      password: password
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    try {
      const { data } = await tokenlessApi.post(
        `${URL_AUTH_BASE_PATH}/users/password/change`, 
        requestBody,
        config
      );
      return data;    
    } catch (err) {
      return null;
    }
  };
}

const authService = new AuthService();
export default authService;
