import { observable, action } from "mobx";
import hangtagService from "~/services/HangtagService";

export class HangtagStore {
  @observable hangtagId = 0;

  @action.bound
  getHangtagsByPage = async (deviceId, pageId, filter, skip) => {
    const data = await hangtagService.getAllByPage(deviceId, pageId, filter, skip);
    if (data === undefined || data === "") return null;
    return data;
  };

  @action.bound
  getHangtag = async (hangtagId) => {
    const data = await hangtagService.get(hangtagId);
    if (data !== undefined && data !== "") {      
      return {...data, hangtagId: data.tagId};
    }
    return {};
  };

  @action.bound
  setHangtagId = (hangtagId) => {
    this.hangtagId = hangtagId;
  }
  
  @action.bound
  update = async (hangtag) => {
    const data = await hangtagService.update(hangtag);
    return data;
  }

  @action.bound
  reorder = async (hangtags) => {
    const data = await hangtagService.reorder(hangtags);
    return data;
  }

  @action.bound
  delete = async (hangtagId) => {
    const data = await hangtagService.delete(hangtagId);
    return data;
  }

  @action.bound
  logout = async () => {
    this.hangtagId = 0; 
  }
}

const hangtagStore = new HangtagStore();
export default hangtagStore;
