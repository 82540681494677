import React from "react";
import { Row, Col } from "reactstrap";
import OrganizationCard from "../../components/OrganizationCard";

const OrganizationsComponent = ({
  userLevel,
  organizations,
  onClick,
  onStatus
}) => {
  return (
    <>
      <div className="content content-xs">       
        <Row>
          <Col>           
            {organizations.map((item, index) => (
              <OrganizationCard
                key={index}
                userLevel={userLevel}
                organizationId={item.organizationId}
                cnpj={item.cnpj}
                name={item.name}
                resale={item.organizationFatherName}
                status={item.status}
                index={index}
                onClick={() => {
                  onClick(item.organizationId);
                }}
                onStatus={() => {
                  onStatus(item.organizationId);
                }}
              />
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OrganizationsComponent;
