import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class FontService {
  getFonts = async (filter) => {
    const url = `${URL_APP_BASE_PATH}/fonts/${filter}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const fontService = new FontService();
export default fontService;