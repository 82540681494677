import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { BsTrash, BsFillPencilFill } from "react-icons/bs";
import Switch from '@mui/material/Switch';

const ProductCard = ({
  code,
  name,
  value,
  available,
  index,
  onClick,
  onDelete,
  onAvailable
}) => (
  <Card style={{ cursor: "pointer", marginBottom: "0px" }} className="card-chart">
    <CardBody style={{padding: "0px", paddingLeft: "5px", backgroundColor: (index % 2 === 0 ? "inherit" : "#1e1e2f")}}>
      <Row>
        <Col md={2} style={{display: "flex", alignItems: "center"}} className="display-none-xs">
           {code}
        </Col>
        <Col xs={4} md={4} style={{display: "flex", alignItems: "center"}}>
          {name}
        </Col>
        <Col xs={2} md={2} style={{display: "flex", alignItems: "center"}}>
           {value}
        </Col>
        <Col style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          <Switch
            checked={available === true}
            onChange={onAvailable}
          />
          <BsTrash
            onClick={onDelete}
            style={{marginLeft: "5px", marginRight: "5px"}}
            className="icon-xs"
            title="Deletar"
            size= {20}
            color="#ec250d"
          /> 
          <BsFillPencilFill              
              size= {20}
              style={{marginLeft: "5px", marginRight: "5px"}}
              title="Editar"
              onClick={onClick}
              color="#4cd678"
              className="icon-xs"
          />
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default ProductCard;
