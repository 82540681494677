import React from "react";
import { inject } from "mobx-react";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import OrganizationComponent from "./OrganizationComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { FILE_UPLOAD_MAX_LENGTH } from "~/configs/constants";
import { ORGANIZATION_TAB, USERS_TAB } from "~/configs/constants";
//import { SUBSCRIPTION_TAB, USER_LEVEL } from "~/configs/constants";
import { isCnpj, getVarFromUrl } from "../../helpers/utils";
import classnames from "classnames";
import Users from "../Users";
//import Subscription from "../Subscription"


@inject("OrganizationStore", "AddressStore", "UserStore")
class OrganizationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: ORGANIZATION_TAB,
      isLoading: true,
      organization: {},
    };
  }

  async componentDidMount() {
    await this.getOrganization();
  }

  onFileChange = async event => {
    try {
      const fileType = event.target.files[0].type;
      const fileSize = event.target.files[0].size;
      if (fileSize > FILE_UPLOAD_MAX_LENGTH) {
        document.getElementById("uploadCaptureInputLogo").value = "";
        this.setState({ selectedFile: null });
        AlertComponent.error("Limite máximo de envio excedido");
      }
      else if (fileType !== 'image/png') {    
        document.getElementById("uploadCaptureInputLogo").value = "";
        this.setState({ selectedFile: null });
        AlertComponent.error("Formato de imagem não suportado");
      }
      else {
        const { organization } = this.state;
        organization.logoUrl = URL.createObjectURL(event.target.files[0]);
        this.setState({ 
          organization: organization,
          selectedFile: event.target.files[0] 
        });
      }
    } catch {}
  };  

  getOrganization = async (organizationName) => {
    this.setState({
      isLoading: true,
    });

    const { activeTab } = this.state;
    const { OrganizationStore } = this.props; 
    const organizationId = getVarFromUrl("organizationId");
    const tab = getVarFromUrl("tab");
    
    let organization = {};
    if (organizationId > 0) organization = await OrganizationStore.getOrganization(organizationId);
    const type = organization?.importation?.type || 1;    
    
    let organizationsData = await this.getOrganizations(organizationName, organization);
    let organizationFather = organizationsData.organizationFather; 
    let organizationsFather = organizationsData.organizationsFather;
 
    const updatedOrganization = {
      ...organization,
      importation: {
        ...organization.importation,
        type: type
      }
    }

    this.setState({
      activeTab: (tab !== undefined  && tab !== null ? tab : activeTab),
      organization: updatedOrganization,
      organizationsFather,
      organizationFather,
      isLoading: false,
    });
  };

  getOrganizations = async (value, organization) => {
    const { OrganizationStore } = this.props;
    let organizationFather = {}
    let organizationsData = await OrganizationStore.getOrganizations(value,0,true);
    let organizationsFatherAux = organizationsData.organizations;
    let organizationsFather = [];
    if (organization.organizationFatherId != null && organization.organizationFatherId !== undefined) {
      organizationFather = await OrganizationStore.getOrganization(organization.organizationFatherId);
      organizationsFather.push(organizationFather);
    }    
    for (var i = 0; i < organizationsFatherAux.length; i++){      
      if (organizationsFatherAux[i].organizationId !== organization.organizationId &&
        organizationsFatherAux[i].organizationId !== organization.organizationFatherId
      )
        organizationsFather.push(organizationsFatherAux[i]);
    }
    
    organizationsFather = organizationsFather.sort((a, b) => a.organizationId - b.organizationId);
    if (organizationFather.organizationId === undefined)
      organizationFather = organizationsFather[0];
    return {
      organizationsFather,
      organizationFather
    }
  };

  goOrganizations = () => {
    const { history } = this.props;
    history.push(`organizations`);
  };

  maintainorganization = async () => {
    this.setState({
      isLoading: true
    });

    const { organization, selectedFile } = this.state;
    const { OrganizationStore } = this.props;    
    //if ((selectedFile === undefined || selectedFile === null) && (organization.logoUrl === undefined || organization.logoUrl === "")) AlertComponent.error("Nenhum arquivo selecionado");
    if (!isCnpj(organization.cnpj)) AlertComponent.error("CNPJ inválido");
    else if (organization.name === undefined || organization.name === "" || organization.name === null) AlertComponent.error("Nome inválido");
    else {
      const formData = new FormData();
      if (selectedFile !== undefined && selectedFile !== null) {      
        formData.append(selectedFile.name, selectedFile);
      }
      formData.append("payload", JSON.stringify(organization));
      const result = await OrganizationStore.maintainOrganization(formData);
      if (result && result?.organizationId > 0) {
        AlertComponent.success("Loja salva com successo");
        this.goOrganizations();
      } else if (result !== undefined && result !== null && result.error !== "") {
        AlertComponent.error(result.error);
      } else {
        AlertComponent.error("Falha ao salvar loja");
      }
    }
    this.setState({
      isLoading: false
    });
  };

  onChange = (e) => {
    const { organization } = this.state;
    const updatedOrganization = {
      ...organization,
      [e.target.name]: e.target.value
    }

    this.setState({
      organization: updatedOrganization,
    });
  };

  onChangeAddress = (e) => {
    const { organization } = this.state;
    const updatedOrganization = {
      ...organization,
      address: {    
        ...organization.address,      
        [e.target.name]: e.target.value,
      }
    }

    this.setState({
      organization: updatedOrganization,
    });

    if (e.target.name === "zipcode") this.onZipCodeChange(e.target.value);
  };

  onZipCodeChange = async (zipCode) => {
    zipCode = zipCode.replace(".","").replace("-","");
    if (zipCode.length === 8) {
      try {
        this.setState({
          isLoading: true,
        });

        const { AddressStore } = this.props; 
        let address = await AddressStore.getCep(zipCode);
        
        const { organization } = this.state;
        const updatedOrganization = {
          ...organization,
          address: {
            ...organization.address,        
            zipcode: address.cep,
            street: address.street,
            city: address.city,
            state: address.state,
            neighborhood: address.neighborhood
          }
        }
        this.setState({
          organization: updatedOrganization,
          isLoading: false,
        });
      }
      catch {
        this.setState({
          isLoading: false
        });
      }
    }
  }

  onChangeImportation = (e) => {
    const { organization } = this.state;
    const updatedOrganization = {
      ...organization,
      importation: {
        ...organization.importation,
        [e.target.name]: e.target.value
      }
    }

    this.setState({
      organization: updatedOrganization,
    });
  };

  onKeyUpOrganizationFather = async (value) => {
    try {
      const { organization } = this.state;
      let organizationsData = await this.getOrganizations(value, organization);
      let organizationFather = organizationsData.organizationFather; 
      let organizationsFather = organizationsData.organizationsFather;
      this.setState({
        organizationFather,
        organizationsFather
      });
    }
    catch {}
  };

  onChangeOrganizationFather = (organizationName) => {
    let { organizationsFather, organizationFather } = this.state;
    const { organization } = this.state;
    for(let i = 0; i < organizationsFather.length; i++){
      if (organizationsFather[i].name === organizationName){
        organizationFather = organizationsFather[i];
        break;
      }
    }   
    const updatedOrganization = {
      ...organization,
      organizationFatherId: organizationFather?.organizationId,
    }

    this.setState({
      organization: updatedOrganization,
      organizationFather: organizationFather
    });
  };

  tabToggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab === tab) return;
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { isLoading, organization, activeTab, organizationsFather, organizationFather } = this.state;
    const { history, UserStore } = this.props;
    return (      
      <>       
        <div className="content content-xs">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === ORGANIZATION_TAB })}
                onClick={() => {
                  this.tabToggle(ORGANIZATION_TAB);
                }}
              >
                Estabelecimento
              </NavLink>
            </NavItem>
            {(organization.organizationId > 0 && (<>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === USERS_TAB })}
                onClick={() => {
                  this.tabToggle(USERS_TAB);
                }}
              >
                Usuários
              </NavLink>
            </NavItem>
            {/* {UserStore.getLevel() === USER_LEVEL.manager ?
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === SUBSCRIPTION_TAB })}
                onClick={() => {
                  this.tabToggle(SUBSCRIPTION_TAB);
                }}
              >
                Mensalidade
              </NavLink>
            </NavItem>
            : (<></>)} */}
            </>
            ))}   
          </Nav>          
          <TabContent activeTab={activeTab}>
            <TabPane tabId={ORGANIZATION_TAB}>
              {(isLoading && (
                <>
                  <LoadingComponent />
                </>
              )) || (
                <OrganizationComponent
                  organization={organization}
                  onClick={this.maintainorganization}
                  onCancel={this.goOrganizations}
                  onChange={this.onChange}
                  onChangeImportation={this.onChangeImportation}
                  onFileChange={this.onFileChange}
                  onChangeAddress={this.onChangeAddress}
                  onKeyUpOrganizationFather={this.onKeyUpOrganizationFather}
                  onChangeOrganizationFather={this.onChangeOrganizationFather}
                  organizationsFather={organizationsFather}
                  organizationFather={organizationFather}
                  userLevel={UserStore.getLevel()}
                />
              )}
            </TabPane>
            {organization.organizationId > 0 ?
              <TabPane tabId={USERS_TAB}>
                <Users history={history} />
              </TabPane>
            : (<></>)}
            {/* {UserStore.getLevel() === USER_LEVEL.manager ?
            <TabPane tabId={SUBSCRIPTION_TAB}>
              <Subscription history={history} />
            </TabPane>
            : (<></>)} */}
          </TabContent>
        </div>
      </>      
    );    
  }
}

export default OrganizationContainer;


