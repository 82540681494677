/* eslint-disable no-empty */
/* eslint-disable no-undef */
import { action } from "mobx";
import wsService from "../services/WsService";

export class WsStore {
  @action.bound
  send = async () => {
    return await wsService.send();
  }; 
}

const wsStore = new WsStore();
export default wsStore;
