import React from "react";
import { inject } from "mobx-react";
import {
  Button,
  Row,
  Col,
} from "reactstrap";

import ImagesComponent from "./ImagesTabComponent";
import LoadingComponent from "~/components/LoadingComponent";
import PaginationComponent from "~/components/PaginationComponent";
import { getVarFromUrl } from "../../../helpers/utils";
import { PAGES_IMAGES_TAB } from "../../../configs/constants";

@inject("ImageStore", "DeviceStore", "PageStore")
class ImagesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      images: [],
    };
  }

  async componentDidMount() {
    this.getImages();
  }

  getImages = async (skip) => {
    this.setState({
      isLoading: true,
    });
    if (skip === undefined) skip = 0;
    const { ImageStore } = this.props;
    const pageId = getVarFromUrl("pageId");

    let imagesAux = [];
    let countItems = 0;
    let currentPage = 1;
    let countPages = 1;

    if (pageId > 0) {
      const data = await ImageStore.getImages(pageId, skip); 
      if (data && data !== "" && data.images.length > 0) {
        imagesAux = data.images;
        countItems = data.countItems;
        countPages = data.countPages;
      }
    }
    this.setState({
      images: imagesAux,
      currentPage: currentPage,
      countItems: countItems,
      countPages: countPages,
      skip: skip,
      isLoading: false,
    });
  };

  onClick = (imageId) => {
    const { history } = this.props;
    history.push(`image?deviceId=${getVarFromUrl("deviceId")}&pageId=${getVarFromUrl("pageId")}&imageId=${imageId}`);
  }

  onDelete = async (imageId) => {
    this.setState({
      isLoading: true
    });
    const { ImageStore, history } = this.props;
    await ImageStore.deleteImage(imageId);
    this.getImages();
    history.push(`page?deviceId=${getVarFromUrl("deviceId")}&pageId=${getVarFromUrl("pageId")}&tab=${PAGES_IMAGES_TAB}`);
  };

  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { ImageStore } = this.props;
    const { images } = this.state;
    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    ImageStore.reorder(items);

    this.setState({
      images: items
    });
  };

  onPageChange = async (currentPage) => {  
    const skip = (currentPage - 1) * 100;
    await this.getImages(skip);
  };

  render() {
    const { isLoading, images, currentPage, countItems } = this.state;
    return (
      <>
        {(isLoading && (
            <LoadingComponent />
        )) || (
          <>
            <Row>
              <Col style={{textAlign: "right"}}>
                <Button
                  className="btn-fill btn-xs"
                  color="primary"
                  type="submit"
                  onClick={()=>this.onClick(0)}
                >
                  Nova mídia
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={1}>
                Código
              </Col>
              <Col xs={4} md={4}>
                Nome
              </Col>
              <Col xs={3} md={3}>
                Tipo
              </Col>             
              <Col md={1} className="display-none-xs">
                Tempo(s)
              </Col>    
            </Row>  
            {(images && (
              <ImagesComponent
                images={images}
                onClick={this.onClick}
                onDelete={this.onDelete}
                handleOnDragEnd={this.handleOnDragEnd}
              />)
            )}
            {(images && (
              <PaginationComponent
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={countItems}
                onPageChange={this.onPageChange}
                pageSize={countItems}
              />)
            )}
          </>          
        )}
      </>
    );
  }
}

export default ImagesContainer;