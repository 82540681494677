import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class SubscriptionService {
  getByOrganization = async (organizationId) => {
    const url = `${URL_APP_BASE_PATH}/subscriptions/organization/${organizationId}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  maintain = async (subscription) => {
    const url = `${URL_APP_BASE_PATH}/subscriptions`;
    try {
      const { data } = await api.post(url, subscription);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const subscriptionService = new SubscriptionService();
export default subscriptionService;
