import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { IMAGE_TYPE } from "../../configs/constants";
import { BsTrash, BsFillPencilFill } from "react-icons/bs";

const ImageCard = ({
  showTime,
  name,
  id,
  type,
  index,
  onClick,
  onDelete
}) => (
  <Card style={{ cursor: "pointer", marginBottom: "0px" }} className="card-chart">
    <CardBody style={{padding: "8px", paddingLeft: "5px", backgroundColor: (index % 2 === 0 ? "inherit" : "#1e1e2f")}}>
      <Row>
        <Col xs={2} md={1} style={{display: "flex", alignItems: "center"}}>          
          {id}
        </Col>
        <Col xs={4} md={4} style={{display: "flex", alignItems: "center"}}>          
          {name}
        </Col>
        <Col xs={3} md={3} style={{display: "flex", alignItems: "center"}}>          
          {type === IMAGE_TYPE.image ? "Imagem" : (<></>)}
          {type === IMAGE_TYPE.video ? "Vídeo" : (<></>)}
          {type === IMAGE_TYPE.folder ? "Cartaz" : (<></>)}
          {type === IMAGE_TYPE.notice ? "Informativo" : (<></>)}
        </Col>      
        <Col md={1} className="display-none-xs">
          {showTime}
        </Col>
        <Col xs={3} md={3} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>          
          <BsTrash
            onClick={onDelete}
            title="Deletar"
            size= {20}
            color="#ec250d"
            className="icon-xs-0"
          /> 
          <BsFillPencilFill              
              size= {20}
              style={{marginLeft: "5px", marginRight: "5px"}}
              title="Editar"
              onClick={onClick}
              color="#4cd678"
              className="icon-xs"
          />
        </Col>
      </Row> 
    </CardBody>
  </Card>
);

export default ImageCard;
