// eslint-disable-next-line import/prefer-default-export
export const formatCentsToRealStr = (value) => {
  if (value == null || value === undefined)
    return "0,00";
  return (value / 100).toFixed(2).replace(".", ",");
};

export const valueCurrencyToInt = (inValueStr) => {
  let valueStr = inValueStr;
  if (valueStr !== null && valueStr !== undefined && valueStr !== ""){
    valueStr = valueStr.replace(".","");
    const splitValue = valueStr.split(',');
      valueStr = "0"; 
    if (splitValue.length === 1)
      valueStr = splitValue[0].concat("00");
    else if (splitValue.length === 2)
      valueStr = splitValue[0].concat(splitValue[1].padEnd(2, "0").slice(0,2));
  }
  else valueStr = "0";
  return parseInt(valueStr, 10);
};
