import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class HangtagService {
  getAllByPage = async (deviceId, pageId, filter, skip) => {
    let url = `${URL_APP_BASE_PATH}/tags/page?deviceId=${deviceId}&pageId=${pageId}&skip=${skip}`;
    if (filter !== "" && filter !== null && filter !== undefined) url += `&filter=${filter}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  get = async (hangtagId) => {
    const url = `${URL_APP_BASE_PATH}/tags/${hangtagId}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  update = async (hangtag) => {
    const url = `${URL_APP_BASE_PATH}/tags`;
    try {
      const { data } = await api.put(url,hangtag);
      return data;
    } catch (err) {
      return null;
    }
  };

  delete = async (hangtagId) => {
    const url = `${URL_APP_BASE_PATH}/tags/${hangtagId}`;
    try {
      const { data } = await api.delete(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  reorder = async (tags) => {
    const url = `${URL_APP_BASE_PATH}/tags/reorder`;
    try {
      const { data } = await api.put(url,tags);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const hangtagService = new HangtagService();
export default hangtagService;
