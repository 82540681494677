import React from "react";
import { inject } from "mobx-react";

import ProductComponent from "./ProductComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { valueCurrencyToInt }  from "../../helpers/monetary"
import { getVarFromUrl } from "../../helpers/utils";

@inject("ProductStore", "UserStore", "OrganizationStore")
class ProductContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      product: {},
    };
  }

  async componentDidMount() {
    await this.getProduct();
  }

  getProduct = async () => {
    this.setState({
      isLoading: true,
    });

    const { ProductStore } = this.props;
    const productId = getVarFromUrl("productId");
    let product = {};
    let organizations = [];

    if (productId !== undefined && parseInt(productId) > 0){
      product = await ProductStore.getProduct(productId);
      product.valueStr = (product.value / 100.00).toFixed(2);
      organizations = await this.getOrganizations("", product.organizationId);
    }
    else { 
      product.productId = 0;
      product.valueStr = 0.00.toFixed(2);
      organizations = await this.getOrganizations();
    }

    this.setState({
      organization: organizations[0],
      organizations: organizations,
      product,
      isLoading: false,
    });
  };

  getOrganizations = async (organizationName, organizationId) => {
    const { OrganizationStore } = this.props;
    let organizationsData = await OrganizationStore.getOrganizations(organizationName, organizationId);
    return organizationsData.organizations;
  };

  goProducts = () => {
    const { history } = this.props;
    history.push(`products`);
  };

  onKeyUpOrganization = async (value) => {
    try {
      let organizations = await this.getOrganizations(value);
      this.setState({
        organizations
      });
    }
    catch {}
  };

  onChangeOrganization = (organizationName) => {
    let { organizations, organization} = this.state;
    for(let i = 0; i < organizations.length; i++){
      if (organizations[i].name === organizationName){
        organization = organizations[i];
        break;
      }
    }   
    this.setState({
      organization: organization
    });
  };

  maintainProduct = async () => {
    const { product, organization } = this.state;
    if (organization === null || organization === undefined || organization.organizationId === 0) AlertComponent.error("Estabelecimento inválido");
    else {
      this.setState({
        isLoading: true
      });    
      product.organizationId = organization.organizationId;
      const { ProductStore } = this.props;
      const result = await ProductStore.maintainProduct(product);
      if (result === true || result === "true"){
        AlertComponent.success("Produto salva com successo");
        this.goProducts();
      } else {
        AlertComponent.error("Falha ao salvar produto");
      }

      this.setState({
        isLoading: false
      });
    }
  };

  onChangeValue = (e) => {
    const { product } = this.state;
    product.value = valueCurrencyToInt(e.target.value);
    this.setState({
      product
    });
  };

  onChange = (e) => {
    const { product } = this.state;
    const updatedProduct = {
      ...product,
      [e.target.name]: e.target.value
    }

    this.setState({
      product: updatedProduct,
    });
  };

  render() {
    const { isLoading, product, organizations, organization } = this.state;
    const { UserStore } = this.props;
    return (
      <>
        {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (
          <div className="content content-xs">
            <ProductComponent
              product={product}
              onClick={this.maintainProduct}
              onCancel={this.goProducts}
              onChangeValue={this.onChangeValue}
              onChange={this.onChange}
              organizations={organizations}
              organization={organization}
              onKeyUpOrganization={this.onKeyUpOrganization}
              onChangeOrganization={this.onChangeOrganization}
              userLevel={UserStore.getLevel()}
            />
          </div>
        )}
      </>
    );
  }
}

export default ProductContainer;
