import React from "react";
import { Row, Col } from "reactstrap";
import ImportationCard from "~/components/ImportationCard";

const ImportationsComponent = ({
  files,
}) => {
  return (
    <>
    <div className="content content-xs">          
      <Row>
        <Col>              
          {files.map((item, index) => (
            <ImportationCard
              key={index}
              register={item.register}
              update={item.update}
              lines={item.lines}
              size={item.size}
              status={item.status}
              index={index}
            />
          ))}
        </Col>
      </Row>
    </div>
    </>
  );
};

export default ImportationsComponent;
