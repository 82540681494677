/* eslint-disable no-undef */
import jwtDecode from "jwt-decode";
import { TOKEN_KEY } from "../configs/constants";

export class Auth {
  isAuthenticated = () => {
    try {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token == null) return false;
      const { exp, passwordReset } = jwtDecode(token);
      if (passwordReset !== undefined && passwordReset !== null && passwordReset === "True")  return false;
      const isAutenticatedV = exp > new Date().getTime() / 1000;
      return isAutenticatedV;
    } catch (e) {
      return false;
    }
  };

  getToken = () => localStorage.getItem(TOKEN_KEY);

  getTokenData = () => jwtDecode(localStorage.getItem(TOKEN_KEY));

  logout = () => {
    localStorage.removeItem(TOKEN_KEY);
  };
}

const auth = new Auth();
export default auth;
