import React from "react";
import PageCard from "~/components/PageCard";
import AlertComponent from "~/components/AlertComponent";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const PagesComponent = ({
  pages,
  onClick,
  onDelete,
  handleOnDragEnd
}) => {
  return (    
  <>
      <div className="content content-xs" style={{marginTop: "5px"}}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="pages">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef} style={{padding: "0px"}}>        
                {pages?.map((item, index) => {
                  return (                    
                    <Draggable key={item.pageId.toString()} draggableId={item.pageId.toString()} index={index}>
                       {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <PageCard
                            id={item.pageId}
                            description={item.description}
                            type={item.type}
                            tagsCount={item.tagsCount}
                            imagesCount={item.imagesCount}
                            index={index}
                            onClick={() => {
                              onClick(item.pageId);
                            }}
                            onDelete={() => {
                              AlertComponent.dialog("Irá apagar todas as etiquetas e mídias exibidas nesta página.",
                                function dialoagNo() {
                                },
                                function dialoagYes() {
                                  onDelete(item.pageId);
                                }    
                              );              
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>        
      </div>
    </>
  );
};

export default PagesComponent;
