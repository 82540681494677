import React from "react";
import PropTypes from "prop-types";
import CurrencyInput from 'react-currency-input-field';
import { USER_LEVEL } from "../../configs/constants";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx, isEmptyOrNullStr } from '../../helpers/utils'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";

const ProductComponent = ({
  onChangeValue,
  onChange,
  onClick,
  onCancel,
  product,
  onKeyUpOrganization,
  onChangeOrganization,
  organization,
  organizations,
  userLevel
}) => {
  return (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 className="title">Produto</h5>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onClick();
                  }}
                >
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <label>Id</label>
                        <Input
                          id="productId"
                          name="productId"
                          value={product?.productId}
                          placeholder="Id do Produto"
                          type="text"
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <label>Codigo do produto</label>
                        <Input
                          name="code"
                          type="text"
                          placeholder="Codigo do produto"
                          value={product?.code}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {userLevel === USER_LEVEL.manager ?
                    <Row>                                         
                      <Col xs={12} md={12}>
                        <FormGroup>
                          <label style={{marginBottom: "0px"}}>Estabelecimento</label>
                          <Autocomplete
                            readOnly={product?.productId !== 0}
                            noOptionsText={"Nenhum item"} 
                            onKeyUp={(event, value) =>   
                              onKeyUpOrganization(document.getElementById("organization").value)
                            }                          
                            onChange={(event, value) =>                         
                              onChangeOrganization(value)
                            }
                            style={{paddingBottom: "10px"}}                                      
                            value={organization?.name}
                            id="organization"
                            options={organizations.map((organization) => organization.name)}
                            isOptionEqualToValue={(option, value) => option.organizationId === value.organizationId}
                            size="small"
                            renderInput={(params) => (
                              <TextField  {...params} variant='standard'/>
                            )}
                            sx={autoCompleteSx}
                            renderOption={(props, option) => {
                              return (
                                <span  {...props}>
                                  {option}
                                </span>
                              );
                            }}                      
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  : (<></>)}                           
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <label>Nome</label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Nome"
                          value={product?.name}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <label>Nome customizado</label>
                        <Input
                          name="description"
                          type="text"
                          placeholder="Nome customizado"
                          value={product?.description}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>          
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>Valor</label>
                        <CurrencyInput
                            className="form-control"
                            defaultValue={product?.valueStr}
                            name="value"
                            decimalsLimit="2"             
                            placeholder="0,00"
                            onChange={onChangeValue}
                        />
                      </FormGroup>
                    </Col>
                    {!isEmptyOrNullStr(product?.imagePath) ?
                    <Col style={{textAlign: "center"}}>
                      <FormGroup>
                        <img alt="" src={product?.imagePath} style={{maxWidth: "200px", maxHeight: "200px"}}/>  
                      </FormGroup>
                    </Col>
                    : (<></>)}
                  </Row>                
                  <Row>
                    <Col style={{textAlign: "right"}}>
                      <Button
                        className="btn-fill btn-xs"
                        color="danger"
                        type="button"
                        onClick={()=>onCancel()}
                      >
                        Voltar
                      </Button>
                      <Button
                        className="btn-fill btn-xs"
                        color="primary"
                        type="submit"
                      >
                        Salvar
                      </Button>
                    </Col>
                  </Row>                  
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
  );
};

ProductComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired
};

export default ProductComponent;