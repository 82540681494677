import React from "react";
import { inject } from "mobx-react";
import {
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";

import OrganizationsComponent from "./OrganizationsComponent";
import LoadingComponent from "../../components/LoadingComponent";
import { USER_LEVEL } from "../../configs/constants";
import AlertComponent from "~/components/AlertComponent";
import PaginationComponent from "../../components/PaginationComponent";

@inject("OrganizationStore", "UserStore")
class OrganizationsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      filter: "",
      organizations: [],
    };
  }

  async componentDidMount() {
    this.getOrganizations();
  }

  getOrganizations = async (skip) => {
    this.setState({
      isLoading: true,
    });

    if (skip === undefined) skip = 0;

    const { filter } = this.state;
    const { OrganizationStore } = this.props;
    const data = await OrganizationStore.getOrganizations(filter, 0, undefined, skip);    
    let countItems = 0;
    let currentPage = 1;
    let countPages = 1;
    let organizations = [];
    if (data && data !== "" && data.organizations.length > 0) {
      organizations = data.organizations;
      countItems = data.countItems;
      countPages = data.countPages;
    }  
    
    this.setState({
      skip: skip,
      countItems: countItems,
      countPages: countPages,
      currentPage: currentPage,
      organizations: organizations,
      isLoading: false,
    });
  };

  goOrganization = async (organizationId) => {
    const { history } = this.props;
    history.push(`organization?organizationId=${organizationId}`);
  };

  onStatus = async (organizationId) => {
    this.setState({
      isLoading: true,
    });
    const { OrganizationStore } = this.props;
    const result = await OrganizationStore.statusUpdate(organizationId);
    this.setState({
      isLoading: false,
    });
    if (result === "true" || result === true) {
      this.getOrganizations();
      AlertComponent.success("Estabelecimento atualizado com sucesso");
    }
    else AlertComponent.error("Falha ao atualizar estabelecimento"); 
  };

  onChangeFilter = (e) => {
    this.setState({
      filter: e.target.value,
    });
  };

  onPageChange = async (currentPage) => {  
    this.setState({
      isLoading: true
    });  
    const skip = (currentPage - 1) * 100;
    await this.getOrganizations(skip);
    this.setState({
      currentPage: currentPage
    });
    this.setState({
      isLoading: false,
      skip: skip
    });
  };

  render() {
    const { UserStore } = this.props;
    const { isLoading, organizations, filter, currentPage, countItems, skip } = this.state;
    return (
      <>
        {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (
          <>         
          <div className="content content-xs">
            <Row>
              <Col>
                <label>Filtro (Nome ou CNPJ)</label>
                <Input
                  name="filter"
                  type="text"
                  placeholder="Filtro"
                  value={filter}
                  onChange={(e) => this.onChangeFilter(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{marginTop: "5px"}}>            
                <Button
                  className="btn-fill btn-xs"
                  color="primary"
                  type="submit"
                  onClick={()=>this.getOrganizations(skip)}
                >
                  Buscar
                </Button>
              </Col>
            </Row>
            {UserStore.getLevel() === USER_LEVEL.manager ?
              <Row>                
                <Col style={{textAlign: "right"}}>
                  <Button
                    className="btn-fill btn-xs"
                    color="primary"
                    type="submit"
                    onClick={()=>this.goOrganization(0)}
                  >
                    Novo estabelecimento
                  </Button>
                </Col>
              </Row>
            : (<></>)}
            <Row>
              <Col xs={4} md={3} style={{lineHeight: "1"}}>
                CNPJ
              </Col>
              <Col xs={4} md={4} style={{lineHeight: "1"}}>
              Nome
              </Col>
              <Col md={3} style={{lineHeight: "1"}} className="display-none-xs">
              Revenda
              </Col>
            </Row>
            <OrganizationsComponent
              userLevel={UserStore.getLevel()}
              organizations={organizations}
              onClick={this.goOrganization}
              onStatus={this.onStatus}
            />
            <PaginationComponent
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={countItems}
              onPageChange={this.onPageChange}
            />
          </div>         
          </>
        )}
      </>
    );
  }
}

export default OrganizationsContainer;
