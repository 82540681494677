import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { PAGE_TYPE, PAGE_FREQUENCY, DAYS_OF_WEEK } from "~/configs/constants";
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx, autoCompleteMultiSx, dateFormatCalender } from '../../helpers/utils'
import InputColor from 'react-input-color';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function mountEvent(name,value) {
  return {
    target:{
      name: name,
      value: value,
    }
  };
};

const PageComponent = ({
  onChange,
  onClick,
  onCancel,
  page,
  onChangeLayout,
  layout,
  layouts,
  layoutIsLoading,
  onKeyUpLayout,
  onChangeColor,
  onChangeTagsColor,
  onChangeFontColor,
  fontIsLoading,
  onKeyUpFont,
  onChangeFont,
  fonts,
  font,
  calendarMinDate,
  onChangePeriod,
  onChangeDaysOfWeek
}) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <h5 className="title">Página</h5>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <span>Dispositivo</span>
                    <Input
                      id="deviceId"
                      name="deviceId"
                      value={page?.deviceId}
                      placeholder="Dispositivo"
                      type="text"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <span>Descrição</span>
                    <Input
                      id="description" 
                      name="description"
                      type="text"
                      placeholder="Descrição"
                      value={page?.description}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <span>Tipo</span><br/>
                    <ButtonGroup style={{width: "100%"}}>
                      <Button style={{width: "25%"}} color="info" className={classNames("btn-simple", {active: page?.type === PAGE_TYPE.tags})} outline onClick={()=>onChange(mountEvent("type", PAGE_TYPE.tags))} >Etiquetas</Button>
                      <Button style={{width: "25%"}} color="info" className={classNames("btn-simple", {active: page?.type === PAGE_TYPE.list})} outline onClick={()=>onChange(mountEvent("type", PAGE_TYPE.list))} >Lista</Button>
                      <Button style={{width: "25%"}} color="info" className={classNames("btn-simple", {active: page?.type === PAGE_TYPE.table})} outline onClick={()=>onChange(mountEvent("type", PAGE_TYPE.table))} >Tabela</Button>
                      <Button style={{width: "25%"}} color="info" className={classNames("btn-simple", {active: page?.type === PAGE_TYPE.midia})} outline onClick={()=>onChange(mountEvent("type", PAGE_TYPE.midia))} >Mídia</Button>
                    </ButtonGroup>                        
                  </FormGroup>
                </Col>
              </Row>                         
              {page?.type !== PAGE_TYPE.midia ?
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <span>Tempo de exibição (em segundos)</span>
                    <Input
                      id="durationTimer"
                      name="durationTimer"
                      type="number"
                      placeholder="Tempo de exibição"
                      value={page?.durationTimer}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              : (<></>)}
              {page?.type !== PAGE_TYPE.midia ?                   
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <span>Tema (Layout)</span>                       
                    <Autocomplete
                      noOptionsText={"Nenhum"}
                      onKeyDown={(event, value) =>                         
                        onKeyUpLayout(value)
                      }                   
                      onChange={(event, value) =>                         
                        onChangeLayout(value)
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      loadingText={"Carregando"}
                      loading={layoutIsLoading}
                      style={{paddingBottom: "10px"}}                                      
                      value={layout}
                      id="layout"
                      options={layouts.map((layout) => layout)}
                      size="small"
                      renderInput={(params) => (
                        <TextField  {...params}  variant='standard'/>
                      )}
                      sx={autoCompleteSx}
                      renderOption={(props, option) => {
                        return (
                          <span  {...props}>
                            <Row>
                              <Col xs={12} md={12} style={{textAlign: "center"}}>
                                {option.name}
                              </Col>
                              <br/>
                              <Col xs={12} md={12} style={{textAlign: "center"}}>
                                <img src={option.url} width={"30%"} alt=""/>
                              </Col>
                            </Row>
                          </span>
                        );
                      }}                      
                    />
                  </FormGroup>
                </Col>                 
                <Col style={{textAlign: "right"}}>
                  {layout.url !== "" ?
                    <a target="_blank" rel="noopener noreferrer" href={layout.url}> <span style={{marginLeft: "10px", color: "white", textDecoration: "underline"}}>Visualizar</span></a>
                  : (<></>)}
                </Col>
              </Row>
              : (<></>)}
              {page?.type !== PAGE_TYPE.midia ?            
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <span>Cor de fundo (é usado apenas para quando não houver layout)</span>               
                    <InputColor     
                      initialValue={page?.color}
                      onChange={onChangeColor}
                      className="custom-input-color"
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <span>Cor das etiquetas</span>
                    <InputColor               
                      initialValue={page?.tagsColor}
                      onChange={onChangeTagsColor}
                      className="custom-input-color"
                    />
                  </FormGroup>
                </Col>
              </Row>
              : (<></>)}
              {page?.type !== PAGE_TYPE.midia ?
              <Row>
                <Col xs={12} md={6}>
                <FormGroup>
                    <span>Fontes</span>                       
                    <Autocomplete
                      noOptionsText={"Nenhum"}
                      onKeyDown={(event, value) =>                         
                        onKeyUpFont(value)
                      }                   
                      onChange={(event, value) =>                         
                        onChangeFont(value)
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      loadingText={"Carregando"}
                      loading={fontIsLoading}
                      style={{paddingBottom: "10px"}}                                      
                      value={font}
                      id="font"
                      options={fonts.map((font) => font)}
                      size="small"
                      renderInput={(params) => (
                        <TextField  {...params}  variant='standard'/>
                      )}
                      sx={autoCompleteSx}                     
                      renderOption={(props, option) => {
                        return (
                          <span {...props}>
                            <Row>
                              <Col xs={12} md={12} style={{textAlign: "left"}}>
                                <div style={{fontFamily: `${option.name}`}}>{option.name}</div>
                              </Col>                              
                            </Row>
                          </span>
                        );
                      }}                      
                    />
                  </FormGroup>                  
                </Col>
                <Col xs={12} md={6}>
                <FormGroup>
                    <span>Cor da fonte</span>               
                    <InputColor               
                      initialValue={page?.fontColor}
                      onChange={onChangeFontColor}
                      className="custom-input-color"
                    />
                  </FormGroup>
                </Col>
              </Row>
              : (<></>)}
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <span>Frequência de exibição</span><br/>
                    <ButtonGroup>
                      <Button color="info" className={classNames("btn-simple", {active: page?.frequency === PAGE_FREQUENCY.fixed})} outline onClick={()=>onChange(mountEvent("frequency", PAGE_FREQUENCY.fixed))} >Sempre</Button>
                      <Button color="info" className={classNames("btn-simple", {active: page?.frequency === PAGE_FREQUENCY.dayOfWeek})} outline onClick={()=>onChange(mountEvent("frequency", PAGE_FREQUENCY.dayOfWeek))} >Dia da semana</Button>
                      <Button color="info" className={classNames("btn-simple", {active: page?.frequency === PAGE_FREQUENCY.period})} outline onClick={()=>onChange(mountEvent("frequency", PAGE_FREQUENCY.period))} >Período</Button>
                    </ButtonGroup>                        
                  </FormGroup>
                </Col>               
                {page?.frequency === PAGE_FREQUENCY.dayOfWeek ?
                <Col xs={12} md={8}>
                  <FormGroup>
                    <span>Dias da semana</span>                       
                    <Autocomplete
                      multiple
                      id="daysOfWeek"
                      options={DAYS_OF_WEEK}
                      sx={autoCompleteMultiSx}
                      value={JSON.parse(page.daysOfWeek)}                      
                      onChange={onChangeDaysOfWeek}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.title}
                      renderOption={(props, option, { selected }) => (
                        <li id={option.id} key={option.id} {...props} style={{color: "inherit"}}>
                          <Checkbox
                            id={option.title}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                        </li>
                      )}
                      style={{ width: "100%", color: "rgba(255, 255, 255, 0.8)" }}
                      renderInput={(params) => (
                        <TextField {...params} style={{color: "rgba(255, 255, 255, 0.8)"}} />
                      )}
                    />
                  </FormGroup>
                </Col>
                : (<></>)}
                {page?.frequency === PAGE_FREQUENCY.period ?
                <Col xs={12} md={8}>
                  <FormGroup>
                    <span>Período</span>
                    <Calendar 
                      className="custom-calendar"
                      returnValue="range" 
                      selectRange="true"
                      minDate={calendarMinDate}
                      defaultValue={[
                        dateFormatCalender(page.startDate), 
                        dateFormatCalender(page.endDate)]}
                      onChange={onChangePeriod} />
                  </FormGroup>
                </Col>
                : (<></>)}
              </Row>
              <Row>
                <Col style={{textAlign: "right"}}>                      
                  <Button
                    className="btn-fill btn-xs"
                    color="danger"
                    type="button"
                    onClick={()=>onCancel()}
                  >
                    Voltar
                  </Button>
                  <Button
                    className="btn-fill btn-xs"
                    color="primary"
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>                  
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

PageComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired
};

export default PageComponent;