import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
  
const ChangePasswordComponent = ({ onChange, changePassword, newPassword, passwordConfirmation }) => (
  <div className="content content-xs">
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <h5 className="title">Alterar senha</h5>
          </CardHeader>
          <CardBody>
            <Form>             
              <Row>
                <Col>
                  <FormGroup>
                    <label>Nova senha</label>
                    <Input
                      name="newPassword"
                      type="password"
                      placeholder="Senha"
                      value={newPassword}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Confirmar nova senha</label>
                    <Input
                      name="passwordConfirmation"
                      type="password"
                      placeholder="Senha"
                      value={passwordConfirmation}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="btn-fill"
                    color="primary"
                     onClick={changePassword}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export default ChangePasswordComponent;
