import React from "react";
import { inject } from "mobx-react";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input
} from "reactstrap";

import ProductsComponent from "./ProductsComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { USER_LEVEL } from "../../configs/constants";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx } from '../../helpers/utils'
import PaginationComponent from "../../components/PaginationComponent";

@inject("ProductStore", "UserStore", "OrganizationStore")
class ProductsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      products: [],
      totalProducts: 0,
      searchText:"",
    };
  }

  async componentDidMount() {
    this.getProducts();
  }
  
  findProducts = async (value) => {
    this.setState({
      searchText: value,
    });
    const { organization } = this.state;
    this.getProducts(organization, organization.name, value, false);
  };

  getProducts = async (organization, organizationName, searchText, showLoading, skip) => {
    if (showLoading === undefined)
      this.setState({
        isLoading: true,
      });

    if (skip === undefined) skip = 0;

    const { ProductStore } = this.props;
    let { organizations } = this.state;
    if (organization === undefined) {
      organizations = await this.getOrganizations(organizationName);
      organization = organizations[0];
    }  

    if (searchText === undefined) searchText = ""

    const data = await ProductStore.getProducts(organization.organizationId, searchText, skip);
    let productsAux = [];
    let countItems = 0;
    let currentPage = 1;
    let countPages = 1;
    if (data && data !== "" && data.products.length > 0) {
      productsAux = data.products;
      countItems = data.countItems;
      countPages = data.countPages;
    }  

    this.setState({
      organization: organization,
      organizations: organizations,
      products: productsAux,
      countItems: countItems,
      isLoading: false,
      countPages: countPages,
      currentPage: currentPage
    });
  };

  getOrganizations = async (value) => {
    const { OrganizationStore } = this.props;
    let organizationsData = await OrganizationStore.getOrganizations(value);
    return organizationsData.organizations;
  };

  startImportation = async () => {
    this.setState({
      isLoading: true,
    });
    const { ProductStore } = this.props;
    const result = await ProductStore.importationStart();

    if (result) 
      AlertComponent.success("Atualização iniciada com successo");
    else 
      AlertComponent.error("Falha ao iniciar atualização");

    this.setState({
      isLoading: false,
    });
  };

  goProduct = async (productId) => {
    const { history } = this.props;
    history.push(`product?productId=${productId}`);
  };

  onKeyUpOrganization = async (value) => {
    try {
      let organizations = await this.getOrganizations(value);
      this.setState({
        organizations
      });
    }
    catch {}
  };

  onChangeOrganization = (organizationName) => {
    let organization = undefined;
    const { organizations, searchText } = this.state;
    for(let i = 0; i < organizations.length; i++){
      if (organizations[i].name === organizationName){
        organization = organizations[i];
        break;
      }
    }
    this.setState({
      organization: organization
    });

    this.getProducts(organization, organization.name, searchText);
  };
  
  onDelete = async (productId) => {
    this.setState({
      isLoading: true
    });

    const { ProductStore } = this.props;
    const { organization, searchText } = this.state;
    const result = await ProductStore.delete(productId);
    if (result === "true" || result === true){      
      this.getProducts(organization, organization.name, searchText);
      AlertComponent.success("Produto removido com sucesso");
    } else {
      AlertComponent.error("Falha ao remover produto");
    }

    this.setState({
      isLoading: false
    });
  };

  onAvailable = async (productId) => {
    this.setState({
      isLoading: true
    });

    const { ProductStore } = this.props;
    const { organization, searchText } = this.state;
    const result = await ProductStore.availableUpdate(productId);
    if (result === "true" || result === true){
      this.getProducts(organization, organization.name, searchText);
      AlertComponent.success("Produto atualizado com sucesso");      
    } else {
      AlertComponent.error("Falha ao atualizar produto");
    }

    this.setState({
      isLoading: false
    });
  };

  onDeleteAll = async () => {       
    this.setState({
      isLoading: true
    });

    const { ProductStore } = this.props;
    const { organization, searchText } = this.state;
    const result = await ProductStore.deleteAll(organization.organizationId);
    if (result === "true" || result === true){
      AlertComponent.success("Produtos removidos com sucesso");
      this.getProducts(organization, organization.name, searchText);
    } else {
      AlertComponent.error("Falha ao remover produtos");
    }
    this.setState({
      isLoading: false
    });
  };

  onPageChange = async (currentPage) => {  
    this.setState({
      isLoading: true
    });  
    const skip = (currentPage - 1) * 100;
    const { organization, searchText } = this.state;
    await this.getProducts(organization, organization.name, searchText, false, skip);
    this.setState({
      currentPage: currentPage
    });
    this.setState({
      isLoading: false
    });
  };

  render() {
    const { isLoading, products, searchText, countItems, organization, organizations, currentPage } = this.state;
    const { UserStore } = this.props;
    return (
      <>
        {(isLoading && (
            <LoadingComponent />
        )) || (
          <>
            <div className="content content-xs">
              <Row>
                {UserStore.getLevel() === USER_LEVEL.manager ?
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <span style={{marginBottom: "0px"}}>Estabelecimento:</span>
                      <Autocomplete
                        noOptionsText={"Nenhum item"}
                        onKeyUp={(event, value) =>   
                          this.onKeyUpOrganization(document.getElementById("organization").value)
                        }                      
                        onChange={(event, value) =>                         
                          this.onChangeOrganization(value)
                        }
                        style={{paddingBottom: "10px"}}                                      
                        value={organization?.name}
                        id="organization"
                        options={organizations.map((organization) => organization.name)}
                        isOptionEqualToValue={(option, value) => option.organizationId === value.organizationId}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} variant='standard'/>
                        )}
                        sx={autoCompleteSx}
                        renderOption={(props, option) => {
                          return (
                            <span {...props}>
                              {option}
                            </span>
                          );
                        }}                      
                      />
                    </FormGroup>
                  </Col>
                : (<></>)}                                                     
              </Row>
              <Row>
                <Col xs={12} md={8} >
                  <Input
                    name="code"
                    type="text"
                    placeholder="Parte do código ou parte do nome do produto"
                    value={searchText}
                    onChange={(e) => this.findProducts(e.target.value)}
                  />
                </Col>    
                <Col xs={12} md={4} style={{textAlign: "right"}}>                  
                  <Button
                      className="btn-fill btn-xs"
                      color="danger"
                      type="submit"
                      onClick={()=> {
                        AlertComponent.dialog("Irá apagar todos os produtos, as etiquetas e as mídias.",
                          function dialoagNo() {
                          },
                          this.onDeleteAll,
                          true
                        );
                      }}
                    >
                      Excluir todos  
                    </Button>                                                     
                  <Button
                      className="btn-fill btn-xs"
                      color="primary"
                      type="submit"
                      onClick={()=>this.goProduct(0)}
                    >
                      Novo produto
                    </Button>                                               
                </Col>
              </Row>
             
              {/* <Row>
                <Col>
                  <Button
                    className="btn-fill"
                    color="secondary"
                    type="submit"
                    onClick={()=>this.startImportation()}
                  >
                    Atualizar via integração
                  </Button>
                </Col>                
              </Row> */}              
              <Row>
                <Col md={2} className="display-none-xs">
                  Código
                </Col>
                <Col xs={4} md={4}>
                  Descrição
                </Col>
                <Col xs={2} md={2}>
                  Valor
                </Col>
              </Row>  
              <ProductsComponent
                products={products}
                onClick={this.goProduct}
                totalProducts={countItems}
                onDelete={this.onDelete}
                onAvailable={this.onAvailable}
              />
              <PaginationComponent
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={countItems}
                onPageChange={this.onPageChange}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default ProductsContainer;
