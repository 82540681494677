import React from "react";
import ImageCard from "~/components/ImageCard";
import AlertComponent from "~/components/AlertComponent";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ImagesComponent = ({
  images,
  onClick,
  onDelete,
  handleOnDragEnd
}) => {
  return (
    <>  
      <div className="content content-xs">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="images">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef} style={{padding: "0px"}}> 
                {images?.map((item, index) => {
                  return (       
                    <Draggable key={item.imageId.toString()} draggableId={item.imageId.toString()} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <ImageCard     
                            showTime={item.showTime}
                            name={item.name}
                            id={item.imageId}
                            type={item.type}
                            index={index}
                            onClick={() => {
                              onClick(item.imageId);
                            }}
                            onDelete={() => {
                              AlertComponent.dialog("Irá apagar a mídia.",
                                function dialoagNo() {
                                },
                                function dialoagYes() {
                                  onDelete(item.imageId);
                                }    
                              );
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>        
      </div>
    </>
  );
};

export default ImagesComponent;
