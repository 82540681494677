import React from "react";
import { inject } from "mobx-react";
import DeviceForm from "~/components/DeviceForm";
import AlertComponent from "~/components/AlertComponent";
import LoadingComponent from "~/components/LoadingComponent";

@inject("DeviceStore", "OrganizationStore", "UserStore")
class DeviceTabContainer extends React.Component {
  constructor(props) {
    super(props);    

    this.state = {
      isLoading: true
    };
  };

  async componentDidMount() {
    await this.getDevice();
  };

  getDevice = async (organizationName) => {
    const { DeviceStore, OrganizationStore } = this.props;
    let device = await DeviceStore.getDevice();
          
    let organizationsAux = await this.getOrganizations(organizationName);
    let organizations = [];
    let organization = organizationsAux[0];

    if (device.organizationId !== null && device.organizationId !== undefined && device.organizationId > 0) {
      organization = await OrganizationStore.getOrganization(device.organizationId);
      organizations.push(organization);
    }
    organizations = organizations.concat(organizationsAux);
    this.setState({
      device,
      organizations,
      organization,
      isLoading: false,
    });
  };

  getOrganizations = async (value) => {
    const { OrganizationStore } = this.props;
    let organizationsData = await OrganizationStore.getOrganizations(value);
    return organizationsData.organizations;
  };

  onChangeDevice = (e) => {
    const { device } = this.state;
    const updatedDevice = {
      ...device,
      [e.target.name]: e.target.value
    }

    this.setState({
      device: updatedDevice,
    });
  };

  onChangeTagCount = (e) => {
    const { device } = this.state;  
    device.tagCount = e.target.value;
    this.setState({
      device: device,
    });
  };

  onKeyUpOrganization = async (value) => {
    try {      
      let organizations = await this.getOrganizations(value);
      this.setState({
        organizations
      });
    }
    catch {}
  };

  onChangeOrganization = (organizationName) => {
    let { organizations, organization } = this.state;
    for(let i = 0; i < organizations.length; i++){
      if (organizations[i].name === organizationName){
        organization = organizations[i];
        break;
      }
    }
    const { device } = this.state;
    const updatedDevice = {
      ...device,
      organization,
      organizationId: organization?.organizationId,
    }

    this.setState({
      device: updatedDevice,
      organization: organization
    });
  };

  goUpdate = async () => {
    this.setState({
      isLoading: true,
    });
    const { DeviceStore } = this.props;
    const { device } = this.state;
    if (device.organizationId === undefined || device.organizationId === 0) 
      AlertComponent.error("Estabelecimento inválido");
    else {
      var result = await DeviceStore.update(device);      
      if (result === true || result === "true"){
        AlertComponent.success("Dispositivo atualizado com successo");
      } else {
        AlertComponent.error("Falha ao atualizar dispositivo");
      }
    }   
    this.setState({
      isLoading: false,
    }); 
  };

  goDevices = () => {
    const { history } = this.props;
    history.push(`devices`);
  };

  render() {
    const { UserStore } = this.props;
    const { isLoading, device, organizations, organization } = this.state;
    return (
      <>
      {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (    
        <DeviceForm          
          device={device}
          onChange={this.onChangeDevice}  
          goUpdate={this.goUpdate}  
          onCancel={this.goDevices}
          onChangeTagCount={this.onChangeTagCount}    
          onKeyUpOrganization={this.onKeyUpOrganization}      
          onChangeOrganization={this.onChangeOrganization}
          organizations={organizations}
          organization={organization}
          userLevel={UserStore.getLevel()}
        />
        )}       
      </>
    );
  }
}

export default DeviceTabContainer;
