import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { BsTrash, BsFillPencilFill } from "react-icons/bs";

const CardDevice = ({
  deviceId,
  tagCount,
  organizationName,
  title,
  index,
  onClick,
  onDelete
}) => (
  <Card style={{ cursor: "pointer", marginBottom: "0px" }} className="card-chart">
    <CardBody style={{padding: "8px", paddingLeft: "5px", backgroundColor: (index % 2 === 0 ? "inherit" : "#1e1e2f")}}>
      <Row>      
        <Col xs={2} md={1} style={{display: "flex", alignItems: "center"}}>          
          {deviceId}
        </Col>
        <Col xs={3} md={4} style={{display: "flex", alignItems: "center"}}>
          {title}
        </Col>
        <Col xs={4} md={5} style={{display: "flex", alignItems: "center"}}>
          {organizationName}
        </Col>
        <Col xs={3} md={2} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          <BsTrash
            onClick={onDelete}
            className="icon-xs"
            title="Deletar"
            size= {20}
            color="#ec250d"
          /> 
          <BsFillPencilFill              
              size= {20}
              className="icon-xs"
              style={{marginLeft: "5px", marginRight: "5px"}}
              title="Editar"
              onClick={onClick}
              color="#4cd678"
          />
        </Col>   
      </Row>
    </CardBody>
  </Card>
);

export default CardDevice;