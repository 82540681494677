import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { BsTrash, BsFillPencilFill } from "react-icons/bs";
import { PAGE_TYPE } from "~/configs/constants";
const PageCard = ({
  id,
  description,
  type,
  tagsCount,
  imagesCount,
  index,
  onClick,
  onDelete
}) => (
  <Card style={{ cursor: "pointer", marginBottom: "0px" }} className="card-chart">
    <CardBody style={{padding: "8px", paddingLeft: "5px", backgroundColor: (index % 2 === 0 ? "inherit" : "#1e1e2f")}}>
      <Row>
        <Col xs={2} md={1} style={{display: "flex", alignItems: "center"}}>          
          {id}
        </Col>
        <Col xs={6} md={4} style={{display: "flex", alignItems: "center"}}>
          {description}
        </Col>
        <Col md={3} style={{display: "flex", alignItems: "center"}} className="display-none-xs">
          {(type === PAGE_TYPE.tags ? "Etiquetas": "")}
          {(type === PAGE_TYPE.list ? "Lista": "")}
          {(type === PAGE_TYPE.table ? "Tabela": "")}
          {(type === PAGE_TYPE.midia ? "Mídia": "")}
        </Col>
        <Col md={2} style={{display: "flex", alignItems: "center"}} className="display-none-xs">
          {(type === PAGE_TYPE.midia ? imagesCount : tagsCount)}
        </Col>
        <Col xs={4} md={2} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          <BsTrash
            onClick={onDelete}
            className="icon-xs"
            title="Deletar"
            size= {20}
            color="#ec250d"
          /> 
          <BsFillPencilFill              
              size= {20}
              className="icon-xs"
              style={{marginLeft: "5px", marginRight: "5px"}}
              title="Editar"
              onClick={onClick}
              color="#4cd678"
          />
        </Col>      
      </Row>
    </CardBody>
  </Card>
);

export default PageCard;
