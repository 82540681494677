import { action } from "mobx";
import addressService from "../services/AddressService";

export class AddressStore {

  @action.bound
  getCep = async (zipCode) => {
    const data = await addressService.getCep(zipCode);
    return data;
  };
}

const addressStore = new AddressStore();
export default addressStore;