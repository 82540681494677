import React from "react";
import PropTypes from "prop-types";
import CurrencyInput from 'react-currency-input-field';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormatCalender } from '../../helpers/utils'

const SubscriptionComponent = ({
  onChange,
  onChangeValue,
  onClick,
  onCancel,
  subscription,
  onChangeDueDate
}) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <h5 className="title">Mensalidade</h5>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Código</label>
                    <Input
                      id="subscriptionId"
                      name="subscriptionId"
                      value={subscription?.subscriptionId}
                      type="text"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Estabelecimento</label>
                    <Input
                      id="organizationName"
                      name="organizationName"
                      value={subscription?.organizationName}
                      type="text"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <label>Descrição</label>
                    <Input
                      id="description"
                      name="description"
                      height={"100px"}
                      value={subscription?.description}
                      type="text"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Valor</label>
                    <CurrencyInput
                        className="form-control"
                        defaultValue={subscription?.valueStr}
                        name="value"
                        decimalsLimit="2"             
                        placeholder="0,00"
                        onChange={onChangeValue}
                    />
                  </FormGroup>
                </Col>              
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Próximo vencimento</label>
                    <DatePicker
                      className="form-control"
                      selected={dateFormatCalender(subscription?.nextDueDate)}
                      onChange={onChangeDueDate}
                    />
                  </FormGroup>
                </Col>                      
              </Row>     
              <Row>
                <Col style={{textAlign: "right"}}>
                  <Button
                    className="btn-fill btn-xs"
                    color="danger"
                    type="button"
                    onClick={()=>onCancel()}
                  >
                    Voltar
                  </Button>
                  <Button
                    className="btn-fill btn-xs"
                    color="primary"
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>                  
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

SubscriptionComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired
};

export default SubscriptionComponent;