import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class WsService {
  send = async () => {
    const url = `${URL_APP_BASE_PATH}/ws`;
    try {
      const { data } = await api.post(url);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const wsService = new WsService();
export default wsService;
