import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { USER_LEVEL } from "~/configs/constants";

function mountEvent(name,value) {
  return {
    target:{
      name: name,
      value: value,
    }
  };
};

const UserComponent = ({
  onChange,
  onClick,
  onCancel,
  user,
  userLevel
}) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <h5 className="title">Usuário</h5>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Id</label>
                    <Input
                      id="userId"
                      name="userId"
                      value={user?.userId}
                      placeholder="Id do Usuário"
                      type="text"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Nome</label>
                    <Input
                      name="name"
                      type="text"
                      placeholder="Nome do usuário"
                      value={user?.name}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>         
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>E-mail</label>
                    <Input
                      name="email"
                      type="text"
                      placeholder="E-mail"
                      value={user?.email}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Nível</label><br/>
                    {(userLevel === USER_LEVEL.manager && (<>
                    <ButtonGroup>
                      <Button color="info" className={classNames("btn-simple", {active: user?.level === USER_LEVEL.establishment})} outline onClick={()=>onChange(mountEvent("level", USER_LEVEL.establishment))} >Estabelecimento</Button>
                      <Button color="info" className={classNames("btn-simple", {active: user?.level === USER_LEVEL.resale})} outline onClick={()=>onChange(mountEvent("level", USER_LEVEL.resale))} >Revenda</Button>
                      <Button color="info" className={classNames("btn-simple", {active: user?.level === USER_LEVEL.manager})} outline onClick={()=>onChange(mountEvent("level", USER_LEVEL.manager))} >Administrador</Button>
                    </ButtonGroup>
                    </>
                    ))}
                    {(userLevel !== USER_LEVEL.manager && (<>
                    <Input
                      name="level"
                      type="text"
                      placeholder="Nível"
                      value={user?.level}
                      readOnly="true"
                    />          
                    </>
                    ))}             
                  </FormGroup>
                </Col>                
              </Row>     
              <Row>
                <Col style={{textAlign: "right"}}>
                  <Button
                    className="btn-fill btn-xs"
                    color="danger"
                    type="button"
                    onClick={()=>onCancel()}
                  >
                    Voltar
                  </Button>
                  <Button
                    className="btn-fill btn-xs"
                    color="primary"
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>                  
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

UserComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired
};

export default UserComponent;