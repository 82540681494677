import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { formatCentsToRealStr } from '../../helpers/monetary'
import { CHARGE_STATUS, URL_APP_BASE_PATH } from '../../configs/constants'
import { dateFormatString, isEmptyOrNullStr } from '../../helpers/utils'

const ChargeCard = ({  
  organizationName,
  dueDate,
  value,
  status,
  description,
  billetView,
  invoiceView,
  index
}) => (
  <Card style={{ cursor: "pointer", marginBottom: "0px"  }} className="card-chart">
    <CardBody style={{padding: "8px", paddingLeft: "5px", backgroundColor: (index % 2 === 0 ? "inherit" : "#1e1e2f")}}>
      <Row>      
        <Col xs={4} md={4} style={{display: "flex", alignItems: "center"}}>
          {organizationName} - {description}
        </Col>
        <Col xs={2} md={2} style={{display: "flex", alignItems: "center"}}>
          {formatCentsToRealStr(value)}
        </Col>
        <Col xs={2} md={2} style={{display: "flex", alignItems: "center"}}>
          {(status === CHARGE_STATUS.pending) ? 'Pendente' : ''}
          {(status === CHARGE_STATUS.received) ? 'Pago' : ''}
          {(status === CHARGE_STATUS.overdue) ? 'Vencido' : ''}
        </Col>
        <Col xs={2} md={2} style={{display: "flex", alignItems: "center"}}>
          {dateFormatString(dueDate)}
        </Col>
        <Col>
            {!isEmptyOrNullStr(billetView) ?
              <a target="_blank" rel="noopener noreferrer" href={`${URL_APP_BASE_PATH.replace("/api","")}/view/pdf?url=${billetView}`} download="boleto.pdf"> <span style={{marginLeft: "10px", color: "white", textDecoration: "underline"}}>Boleto</span></a>
            : (<></>)}
            {!isEmptyOrNullStr(invoiceView) ?
              <a target="_blank" rel="noopener noreferrer" href={`${URL_APP_BASE_PATH.replace("/api","")}/view/pdf?url=${invoiceView}`} download="nota.pdf"> <span style={{marginLeft: "10px", color: "white", textDecoration: "underline"}}>Nota</span></a>
            : (<></>)}
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default ChargeCard;