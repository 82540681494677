import React from "react";
import { inject } from "mobx-react";

import UserComponent from "./UserComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { mailValidator, getVarFromUrl } from "~/helpers/utils";
import { USERS_TAB } from "../../configs/constants";

@inject("UserStore", "OrganizationStore")
class UserContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      user: {}
    };
  }

  async componentDidMount() {
    await this.getUser();
  }

  getUser = async () => {
    this.setState({
      isLoading: true,
    });

    const { UserStore } = this.props;
    let organizationId = getVarFromUrl("organizationId");
    const userId = getVarFromUrl("userId");    
    let user = {};
    user.organizationId = organizationId
    
    if (userId !== undefined && parseInt(userId) > 0) {
      user = await UserStore.getUser(userId);
      organizationId = user.organizationId;
    }      

    this.setState({
      organizationId,
      user,
      isLoading: false,
    });
  };

  goOrganization = () => {
    const { history } = this.props;
    history.push(`organization?organizationId=${getVarFromUrl("organizationId")}&tab=${USERS_TAB}`);
  };

  maintainUser = async () => {
    this.setState({
      isLoading: true
    });

    const { user } = this.state;
    const { UserStore } = this.props;
        
    if (!mailValidator(user.email)) {
      AlertComponent.error("E-mail inválido");
    }
    else {
      const result = await UserStore.maintainUser(user);
      if (result && result?.userId > 0){
        AlertComponent.success("Usuário salvo com successo");
        this.goOrganization();
      } 
      else if (result && result.error !== undefined && result.error !== null && result.error !== "") {
        AlertComponent.error(result.error);
      }
      else {
        AlertComponent.error("Falha ao salvar usuário");
      }
    }

    this.setState({
      isLoading: false
    });
  };

  onChange = (e) => {
    const { user } = this.state;
    const updatedUser = {
      ...user,
      [e.target.name]: e.target.value
    }

    this.setState({
      user: updatedUser,
    });
  };

  render() {
    const { isLoading, user } = this.state;
    const { UserStore } = this.props;
    return (
      <>
        {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (
          <div className="content content-xs">
            <UserComponent
              user={user}
              onClick={this.maintainUser}
              onCancel={this.goOrganization}
              onChange={this.onChange}
              userLevel={UserStore.getLevel()}
            />
          </div>
        )}
      </>
    );
  }
}

export default UserContainer;
