import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { BsFillPencilFill } from "react-icons/bs";
import { USER_STATUS } from '../../configs/constants'
import Switch from '@mui/material/Switch';
import { USER_LEVEL } from "../../configs/constants";

const UserCard = ({
  userLevel,
  name,
  email,
  onClick,
  status,
  index,  
  onStatus
}) => (
  <Card style={{ cursor: "pointer", marginBottom: "0px" }} className="card-chart">
    <CardBody style={{padding: "0px", paddingLeft: "5px", backgroundColor: (index % 2 === 0 ? "inherit" : "#1e1e2f")}}>
      <Row>        
        <Col xs={5} md={5} style={{display: "flex", alignItems: "center"}}>
          {name}
        </Col>
        <Col xs={5} md={5} style={{display: "flex", alignItems: "center"}}>
           {email}
        </Col>
        <Col xs={2} md={2} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          <Switch defaultChecked
            checked={status === USER_STATUS.active}
            onChange={onStatus}
            disabled={userLevel !== USER_LEVEL.manager}
          />
          <BsFillPencilFill              
              size= {20}
              style={{marginLeft: "20px", marginRight: "5px"}}
              title="Editar"
              onClick={onClick}
              color="#4cd678"
              className="icon-xs"
          />
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default UserCard;
