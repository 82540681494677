import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

const AssignDeviceComponent = ({
  devices,
  onClick,
}) => {
  return (
    <>
      <Row>
        <Col>           
          {devices.map((item, index) => (
            <Card key={index} style={{ cursor: "pointer", marginBottom: "0px" }} >
              <CardBody style={{padding: "0px", paddingLeft: "5px", backgroundColor: (index % 2 === 0 ? "inherit" : "#1e1e2f")}}>
                <Row>
                  <Col xs={2} md={1} style={{display: "flex", alignItems: "center"}}> 
                    {item.deviceId}
                  </Col>
                  <Col xs={3} md={3} style={{display: "flex", alignItems: "center"}}>
                    {item.title}
                  </Col>
                  <Col xs={3} md={4} style={{display: "flex", alignItems: "center"}}>
                    {item.organizationName}
                  </Col>                  
                  <Col xs={4} md={4} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <Button
                        className="btn-fill btn-xs"
                        color="primary"
                        type="button"
                        onClick={()=>onClick(item.deviceId)}
                      >
                        Associar
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default AssignDeviceComponent;
