import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { Section } from "./styles";

const ForgotPasswordComponent = ({
  onChange,
  responseForgotPassword,
  email,
  setForgotPassword,
  goForgotPassword,
}) => {

  return (
    <Section>
      <div className="content content-xs">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 className="title">Recuperar senha</h5>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    goForgotPassword();
                  }}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>E-mail</label>
                        <Input
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => onChange(e)}
                          placeholder="e-mail"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {responseForgotPassword != null &&
                      responseForgotPassword !== undefined &&
                      responseForgotPassword.message ? (
                        <Alert
                          color={
                            responseForgotPassword.success
                              ? "success"
                              : "danger"
                          }
                        >
                          {responseForgotPassword.message}
                        </Alert>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {responseForgotPassword != null &&
                    responseForgotPassword !== undefined &&
                    responseForgotPassword.success ? (
                      <>
                        <Col>
                          <Button
                            className="btn-fill"
                            color="primary"
                            type="button"
                            onClick={setForgotPassword}
                          >
                            Login
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                      <Col>
                        <Button
                          className="btn-fill btn-xs"
                          color="danger"
                          type="button"
                          onClick={setForgotPassword}
                        >
                          Cancelar
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          className="btn-fill btn-xs"
                          color="primary"
                          type="submit"
                        >
                          Continuar
                        </Button>
                      </Col>
                      </>
                    )}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Section>
  );
};

export default ForgotPasswordComponent;
