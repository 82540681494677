import React, { Component } from "react";
import { inject } from "mobx-react";
import AlertComponent from "~/components/AlertComponent";
import LoadingComponent from "~/components/LoadingComponent";
import LoginComponent from "./LoginComponent";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import logo from "../../assets/logo.png";

const stateForgotPassword = 2;

@inject("UserStore")
class LoginContainer extends Component {
  state = {
    login: "",
   
    password: "",
    error: "",
    isLoading: false,
    stateform: 0,
  };

  constructor(props) {
    super(props);
    const { UserStore, history } = props;

    if (UserStore.isLogged()) history.push("/home");
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  goLogin = async () => {
    const { login, password } = this.state;
    const errors = [];
    if (password == null || password === undefined || password === "")
      errors.push("Senha inválida.");
    if (errors.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        AlertComponent.error(errors[i]);
      }
      return;
    }
    this.setState({
      isLoading: true,
    });
    const { UserStore, history } = this.props;

    await UserStore.login(login, password);
    
    if (UserStore.isLogged()) { 
      this.setState({
        isLoading: false,
      });

      history.push("/home");
    }
    else {
      this.setState({
        isLoading: false,
      });
      AlertComponent.error("Falha ao realizar login");
    }
  };

  goForgotPassword = async () => {
    const { email } = this.state;
    const errors = [];
    if (errors.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        AlertComponent.error(errors[i]);
      }
      return;
    }
    this.setState({
      isLoading: true,
    });

    const { UserStore } = this.props;
    if (email === undefined || email === null || email === "") {
      AlertComponent.error("E-mail inválido");
      this.setState({
        isLoading: false,
      });
    }
    else {
      const data = await UserStore.passwordReset(email);
      if (data != null && data !== undefined && data === true)
        AlertComponent.success("E-mail de redefinição de senha enviado");
      else 
        AlertComponent.error("Falha ao enviar email");
      
      this.setState({
        responseForgotPassword: data,
        isLoading: false,
      });
    }   
  };

  render() {
    const { isLoading, stateform } = this.state;
    return (
      <>
        {(isLoading && <LoadingComponent />) ||
          (stateform === stateForgotPassword && (
            <ForgotPasswordComponent
              {...this.state}
              onChange={this.onChange}
              goForgotPassword={this.goForgotPassword}
              setForgotPassword={() =>
                this.setState({
                  stateform: 0,
                  responseForgotPassword: undefined,
                  email: ""
                })
              }
            />
          )) ||
          (stateform === 0 && (
            <LoginComponent
              {...this.state}
              onChange={this.onChange}
              goLogin={this.goLogin}
              logo={{
                imgSrc: logo,
                outterLink: "https://eprice.net.br"
              }}
              setForgotPassword={() =>
                this.setState({ stateform: stateForgotPassword })
              }
            />
          ))}
      </>
    );
  }
}

export default LoginContainer;
