import { action } from "mobx";
import chargeService from "~/services/ChargeService";

export class ChargeStore {

  @action.bound
  get = async (organizationId, dueDateStart, dueDateEnd, status, skip) => {
    const charges = await chargeService.get(organizationId, dueDateStart, dueDateEnd, status, skip);
    if (!charges || charges === "" || charges.length < 1) return [];

    return charges;    
  };
}

const chargeStore = new ChargeStore();
export default chargeStore;
